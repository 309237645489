@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@400;700&display=swap'); */

html {
    background-color: #f5f5f5;
}

#root {
    height: 100vh;
    width: 100%;
}

input[type=date] {
    color: #11111197 !important;
}

input::placeholder {
    color: #11111197;
}

.custom-radio input[type="radio"], .custom-radio input[type="checkbox"] {
    appearance: none;
    /* Remove default radio button appearance */
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid #ccc;
    border-radius: 50%;
    display: grid;
    place-content: center;
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.custom-radio input[type="radio"]:checked, .custom-radio input[type="checkbox"]:checked {
    border-color: #0e766c;
    /* Change the border color to green when checked */
}

.custom-radio input[type="radio"]::before, .custom-radio input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.2s ease-in-out;
    box-shadow: inset 1em 1em #0e766c;
    /* Green check color */
}

.custom-radio input[type="radio"]:checked::before, .custom-radio input[type="checkbox"]:checked::before {
    transform: scale(1);
    /* Scale the green check when selected */
}